import { openReward } from '../components/rewards.js';

document.querySelectorAll('[data-reward]').forEach(function(elt) {
    elt.addEventListener('click', function() {
        openReward(this, function(response) {
            // Update the HTML element with the class 'sparks' with the value from the JSON response
            const sparksElements = document.querySelectorAll('.js-sparks');
            sparksElements.forEach(element => {
                element.textContent = response.sparks;
            });
        });
    });
});
