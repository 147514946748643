export var loading = function (e) {
    if (e == true) {
        $('body').css('cursor','progress');
        $('#click_disabler').show();
    }
    else if (e == false) {
         $('body').css('cursor','');
         $('#click_disabler').hide();
    }
}

