import { loading } from './loading.js';
import { Confettis } from './confettis.js';

export function openReward(elt, callback) {
    loading(true);
    let url = elt.getAttribute('data-url');

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
    .then(response => response.json())
    .then(response => {
        loading(false);
        if (response.success) {
            let confettis = new Confettis();
            confettis.fire();
        }

        if (callback) {
            callback(response);
        }

        MicroModal.show('default-modal', { disableFocus: true });

        const modalContentElement = document.getElementById('modal-dynamic-content');
        modalContentElement.innerHTML = response.html;
    })
    .catch(error => {
        loading(false);
        console.error('Error:', error);
    });

    return {
        // Object properties
    };
}
