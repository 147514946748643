export class Confettis {
	fire() {
		confetti({
		  particleCount: 100,
		  spread: 100,
		  origin: { y: 0.6 },
		  ticks: 300,
		  colors: ['#FFFFFF']
		});
	}

	explosion() {
		let end = Date.now() + (2 * 1000);

		// go Buckeyes!
		let colors = ['#ffffff'];

		(function frame() {
		  confetti({
		    particleCount: 1,
		    angle: 60,
		    spread: 55,
		    origin: { x: 0 },
		  });
		  confetti({
		    particleCount: 1,
		    angle: 120,
		    spread: 55,
		    origin: { x: 1 },
		  });

		  if (Date.now() < end) {
		    requestAnimationFrame(frame);
		  }
		}());
	}
}